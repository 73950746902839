import React from 'react'
import DisclaimerDesktopImage from '../../assets/images/disclaimer-desktop1.png'
import './Image.css'

export default function DisclaimerImage() {
  return (
    <div className='disclaimer-img-container'>
      <img src={DisclaimerDesktopImage} alt="img"  />
    </div>
  )
}
