import { combineReducers } from 'redux'
import pdfslice from './slice/pdfslice';
import headerslice from './slice/headerslice';

const allReducers  = combineReducers({
  login:pdfslice,
  header:headerslice
})

const rootReducer = (state:any, action:any) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return allReducers(state, action);
}

export default rootReducer;