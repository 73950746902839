import { createSlice,PayloadAction } from '@reduxjs/toolkit';



export const initialState: any = {
    showAvatar: false,
   
};
export const header = createSlice({
  name: 'pdf',
  initialState: initialState,
  reducers: {
    setShowHeaderAvatar:(state:any,action: PayloadAction<any>) => { 
        state.showAvatar=action.payload
      }
    }
});

// this is for dispatch
export const { setShowHeaderAvatar } = header.actions;

// this is for configureStore
export default header.reducer;