import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import "./DeclineModal.css"

 /* istanbul ignore next */
export default function DeclineModal({visible, handleClose}:any) {

    const handleCloseTab = () => {
          window.close();  
    };

  return (
    <Modal
        // hideBackdrop
        open={visible}
        onClose={()=>handleClose(null)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box>
            <div className="decline-wrapper" id="decline-modal-wrapper">
            <div 
                className='close-icon'
                onClick={()=>handleClose(null)}
            >
                 <CloseIcon />            
          </div>
            <div className='decline-modal-body'>
                <h2 className='decline-title'>Are you sure you want to decline ?</h2>
                <div className='decline-modal-box' >
                    <div className='btn-decline-wrapper'>
                        <button className='btn-primary decline-btn' id="declineYes"  onClick={()=>handleCloseTab()}>Yes</button>
                    </div>
                    <div className='btn-decline-wrapper'>
                        <button className='btn-secondary decline-btn' id="declineNo" onClick={()=>handleClose(null)}>No</button>
                    </div>
                </div>
                
            </div>
            </div>
        </Box>
      </Modal>
  )
}

