import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import VerificationOtpImage from '../../assets/images/verification.png';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from 'react-router-dom';
import './VerificationModal.css'
import Verification from './Verification';
import { setShowHeaderAvatar } from '../../app/slice/headerslice';
import { fileInfo } from '../../apis/fileinfo';
import { setOtpValue } from '../../app/slice/pdfslice';



export default function VerificationModal({ show,newMobileNumber, handleClose }: any) {
  const dispatch=useDispatch()
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const inputMobileDigit=useSelector((state:any)=>state.login)
  const {otpValue,id}=useSelector((state:any)=>state.login)
  const [otp, setOtp] = useState("");
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [showEmailId, setShowEmailId] = useState("*******@p360.com");
  const [disable,setDisable]=useState(true)
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  useEffect(()=>{
    console.log(otpValue)
  })
  
  const handleChange = (e: any) => { 
    console.log(otp.length)
    if(e.length==4){
      setDisable(false);
      if(otpValue==e){
      }
    }
    setOtp(e) 
  //  let otpValue= sampleOtp.filter((x)=>x.value === otp)
  // sampleOtp.map()
    
  };
  const clearValue =() =>{
    setOtp('') 
    setShowError(false)
  }

  const verfiyOtp =()=>{
    console.log(otp)
   
    // eslint-disable-line
    if(otp.length <4){
      setShowError(true)  
    }else{
      debugger
        if( otpValue == otp){
          console.log('valid')
          setShowError(false)
          dispatch(setShowHeaderAvatar({showAvatar:true}))
          navigate('/sign/')
          // window.location("/sign/");
        }else{
          setShowError(true)
          setErrorText('OTP is not valid')
        }
    
    }

  }

  const sendOtpAgain=async()=>{
    setOtp('');
    fileInfo(id,newMobileNumber,(res:any)=>{
      if(res){
        dispatch(setOtpValue(res.OTP))
        setDisable(true)
      }
    })
  }
  return (

    <Modal
      // hideBackdrop
      open={show}
      // onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      
    >

      <Box>
        <div className="verification-modal-wrapper" data-testid="verification-modal">
          <div
            className='close-icon'
            id="close_icon-modal"
            data-testid="icon-close-popup"
            onClick={()=>{handleClose(); clearValue()}}
          >
            <CloseIcon />

          </div>

          <div className='modal-box'>
            <div className="verification-container">
              <div className="row row-flex-wrap">
                <div className="col-xl-6 col-md-12 otp-content-container">
                  <h2 className='modal-title'>Enter Verification code</h2>
                  <p className='modal-content'>We have just sent a verification code to {showEmailId} and your phone.</p>
                  <div className="opt-input-container">
                    <OtpInput className='otp-input'
                      value={otp}
                      onChange={(e: any) => { handleChange(e) }}
                      numInputs={4}
                      separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                      hasErrored={showError}
                      isInputNum={true}
                    />
                  </div>
                  {showError? <p className='error'>{errorText}</p>:null}
                  <div className='text-primary-btn' onClick={()=>{sendOtpAgain()}}>Send the code again </div>
                  <div className='text-primary-btn changeNumber'  onClick={()=>{clearValue(); handleClose();setShowError(false)}}>Change phone number</div>
                  <div className='btn-otp-wrapper'>
                    <button className={disable?'otp-btn-primary disable-btn':'otp-btn-primary'}  onClick={verfiyOtp}>Verify</button>
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className='verification-otp-img'>
                    <img src={VerificationOtpImage} alt="otp-img" />
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </Box>
    </Modal>
  );
}


