import React from 'react'
import DisclaimerContent from '../../components/DisclaimerContent/Content'
import DisclaimerImage from '../../components/DisclaimerImage/Image'
import Header from '../../components/Header/Header'
import './Home.css'

export default function Home() {
  return (
    <div className='wrapper'>
        <Header />
        <div className='container'>
            <div className='row'>
                <div className='col-xl-5 col-md-12 left-container'>
                    <DisclaimerImage/>
                </div>
                <div className='col-xl-7 col-md-12 right-container'>
                    <DisclaimerContent />
                </div>
            </div>
        </div>
    </div>
  )
}
