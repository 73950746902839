import React from 'react'
import DisclaimerAgreeButton from '../Button/Button';
import './Content.css'

export default function DisclaimerContent() {
  return (
    <div className=''>
      <h1 className="title">Disclaimer</h1>
      <div className='content-wrapper'>
        <p>Website requires the use of an electronic signature in order to maintain compliance with the PDMA The totality of information you supply constitutes your electronic signature and is the legal equivalent of your handwritten signature when submitted on any sample request form offered on our website. The mobile application captures your digital signature during ordering of drug samples. </p>
        <p>Your electronic signature or digital signature is required when requesting drug samples offered onwebsite and its mobile application version in order to comply with the provisions of the federal Prescription Drug Marketing Act (“PDMA”).</p>
        <p>For as per 21 CF Part 11, vour electronic signature constitutes the unique combination of user ID password and PIN. This is personal to you and only for your use in order to request drug samples and product information on website. These credentials should not be shared with others. For the mobile application with your digital signature is your handwritten signature captured digitally and recorded in the form of pixels with your personal mobile device. You will be asked to sign the following attestation: "I certify that I am the person named herein. I understand that the information presented above constitutes my digital signature, which is the legal equivalent of my handwritten signature and will be accepted as same in a court of law, or before any federal or state requlatory agency with appropriate</p>
      </div>
      <div>
        <DisclaimerAgreeButton />
      </div>      
    </div>
  )
}
