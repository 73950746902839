import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import config from '../../env.json';
import './Header.css'


const logoImage:any = config.REACT_APP_LOGO_IMAGE;

// function stringAvatar(name: string) {
//   return {
//     sx: {
//       bgcolor: stringToColor(name),
//     },
//     children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
//   };
// }
function initialFunction(value:string) {
  const words = value.split(' ');

    // Get the first letter of each word
    const initials = words
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();

    return initials;
}

export default function Header() {
  const showheaderRight=useSelector((state:any)=>state.header)
  let profileName = useSelector((state:any)=>state.login.recepientName)
  let RecepientName:string = 'UN'
  if(profileName.length>0){
  RecepientName = initialFunction(profileName)
  }


  // const [verificationDone, setVerificationDone] = useState<any>(true);
  return (
    <header className='header'>
            <div className="header-left">
                <div className="logo-wrapper">
                    <img src={logoImage} id="home-logo-id" alt="zing signature logo" />
                </div>
            </div>
            {showheaderRight.showAvatar?
            <div className="header-right notMobile">
                <div className="avatar-wrapper">
                <Avatar
                  // sx={{ bgcolor: deepOrange[500] }}
                  className='custom-avatar'
                  alt="Remy Sharp"
                  src="/broken-image.jpg"
                >
                 {RecepientName}
                </Avatar>
                </div> 
                <MoreVertIcon />
            </div> : null}
    </header>
  )
}
