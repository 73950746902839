import React from 'react'
import Header from '../../components/Header/Header'
import Sign from '../../components/Sign/Sign';
import './PdfTron.css'

export default function PdfTron() {
  return (
    <div className='pdftronMain'>
        <Header />
        <Sign />
    </div>
  )
}
