import React from 'react';
import HomePage from './pages/Home/Home';
import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import config from './env.json';
// import Sign from './components/Sign/Sign';
import { Provider } from 'react-redux';
import PdfTron from './pages/PdfTron/PdfTron';


function App() {
  document.documentElement.style.setProperty('--primary', `${config.REACT_APP_PRIMARY_COLOR}`);
  // document.documentElement.style.setProperty('--secondary-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_COLOR}`);
  // document.documentElement.style.setProperty('--tertiary-color', `${config.REACT_APP_MAIN_APP_CSS_TERTIARY_COLOR}`);
  // document.documentElement.style.setProperty('--primary-text-color', `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_TEXT_COLOR}`);
  // document.documentElement.style.setProperty('--secondary-text-color', `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_TEXT_COLOR}`);
  // document.documentElement.style.setProperty('--placeholder-text-color', `${config.REACT_APP_MAIN_APP_CSS_PLACEHOLDER_TEXT_COLOR}`);
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/*"  element={<HomePage />} />
        <Route path="/sign/*" element={<PdfTron />} />
      </Routes>
    </BrowserRouter>
      
    </div>
  );
}

export default App;
